<template>
  <div>
    <v-app>
      <v-container>
        <v-row>
          <!-- loading dialog -->
<v-dialog v-model="loading" persistent :width="width">
  <v-card :color="color" :light="light" :dark="dark">
    <v-card-text>
      <v-card-title> {{ msg }}</v-card-title>
      <v-progress-linear :value="percentComplete" :indeterminate="indeterminate" :color="barColor" class="mb-0">
      </v-progress-linear>
    </v-card-text>
  </v-card>
</v-dialog>
<!-- loading dialog end -->
          <v-col lg="12" md="12" sm="12" xs="12">
            <v-tabs grow v-model="tab">
              <v-tab href="#devamedencalismalar">
                AKTİF ÇALIŞMALAR
              </v-tab>

              <v-tab href="#kilitlicalismalar">
                KİLİTLİ ÇALIŞMALAR
              </v-tab>

              <v-tab href="#arsivlenmiscalismalar">
                ARŞİVLENMİŞ ÇALIŞMALAR
              </v-tab>
            </v-tabs>
            <v-tabs-items :value="tab">
              <v-tab-item value="devamedencalismalar">
                <v-container>
                  <v-row>
                    <v-col class="ml-auto" xl="4" lg="5" md="6">
                      <v-text-field
                        v-model="searchWork"
                        append-icon="search"
                        label="Ara"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      xl="4"
                      lg="4"
                      md="6"
                      sm="12"
                      xs="12"
                      v-for="(item, index) in filteredList"
                      :key="index"
                    >
                      <v-card class="elevation-5">
                        <v-card-title>
                          <v-row>
                            <v-col class="col-10">
                              {{ item.workShortName }}
                            </v-col>
                            <v-col class="col-2 ml-auto">
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn text v-bind="attrs" v-on="on">
                                    ...
                                  </v-btn>
                                </template>

                                <v-list v-if="isShowGlobalAdmin">
                                  <v-list-item
                                    ><button>
                                      <h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                        v-on:click="lockWork(item.id)"
                                      >
                                        Çalışmayı Kilitle
                                      </h5>
                                    </button></v-list-item
                                  >
                                  <v-list-item
                                    ><button>
                                      <h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                        v-on:click="archiveWork(item.id)"
                                      >
                                        Çalışmayı Arşivle
                                      </h5>
                                    </button></v-list-item
                                  >
                                  <router-link
                                    to="/worksdetails/?tab=calismaekibi"
                                  >
                                    <v-list-item
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Çalışma Ekibi
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                  <router-link
                                    to="/worksdetails/?tab=calismadosyalari"
                                  >
                                    <v-list-item
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Çalışma Dosyaları
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                  
                                    <v-list-item
                                    @click="goDetail(item.id)"
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Hastalar
                                      </h5></v-list-item
                                    >
                              
                                  <router-link to="/worksdetails/?tab=hastalar">
                                    <v-list-item
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                            @click="setFromWorkList"
                                      >
                                        Hasta Ekle
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                </v-list>

                                <v-list
                                  v-for="(item2, index) in item.workMembers"
                                  :key="index"
                                >
                                  <v-list-item
                                    v-if="
                                      item2.authorizationGroup == 2 &&
                                        index == 0
                                    "
                                    ><button>
                                      <h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                        v-on:click="lockWork(item.id)"
                                      >
                                        Çalışmayı Kilitle
                                      </h5>
                                    </button></v-list-item
                                  >
                                  <v-list-item
                                    v-if="
                                      item2.authorizationGroup == 2 &&
                                        index == 0
                                    "
                                    ><button>
                                      <h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                        v-on:click="archiveWork(item.id)"
                                      >
                                        Çalışmayı Arşivle
                                      </h5>
                                    </button></v-list-item
                                  >
                                  <router-link
                                    to="/worksdetails/?tab=calismaekibi"
                                  >
                                    <v-list-item v-if="index == 0"
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Çalışma Ekibi
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                  <router-link
                                    to="/worksdetails/?tab=calismadosyalari"
                                  >
                                    <v-list-item v-if="index == 0"
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Çalışma Dosyaları
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                 
                                    <v-list-item v-if="index == 0" @click="goDetail(item.id)"
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Hastalar
                                      </h5></v-list-item
                                    >
                                 
                                  <router-link to="/worksdetails/?tab=hastalar">
                                    <v-list-item
                                      v-if="
                                        item2.authorizationGroup == 1 &&
                                          index == 0
                                      "
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                            @click="setFromWorkList"
                                      >
                                        Hasta Ekle
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                </v-list>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-card-title>

                        <v-card-text class="mt-5">
                          <v-row>
                            <v-col>
                              {{ item.workName }}
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col>
                              Tarih:
                              {{ getDateFormat(item.createDate) }} -
                              {{ getDateFormat(item.completionDate) }}
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col>
                              Protokol No: {{ item.protocolNumber }}
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col> SAK: {{ item.responsibleCro }} </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          <v-row>
                            <v-col style="margin-right:10%" class="col-2 ml-auto mb-2"
                            
                            >
                              <router-link to="/worksdetails/?tab=hastalar">
                                <v-btn v-on:click="getWorkId(item.id)" icon
                                  ><v-icon>fas fa-arrow-right </v-icon>
                                </v-btn>
                              </router-link>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item value="kilitlicalismalar">
                <v-container class="bv-example-row">
                  <v-row>
                    <v-col class="ml-auto" xl="4" lg="5" md="6">
                      <v-text-field
                        v-model="searchWork2"
                        append-icon="search"
                        label="Ara"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      xl="4"
                      lg="6"
                      md="6"
                      sm="6"
                      xs="12"
                      v-for="(item, index) in filteredLockedList"
                      :key="index"
                    >
                      <v-card class="elevation-5">
                        <v-card-title>
                          <v-row>
                            <v-col class="col-10">
                              {{ item.workShortName }}
                            </v-col>
                            <v-col class="col-2 ml-auto">
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn text v-bind="attrs" v-on="on">
                                    ...
                                  </v-btn>
                                </template>

                                <v-list v-if="isShowGlobalAdmin">
                                  <v-list-item
                                    ><button>
                                      <h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                        v-on:click="archiveWork(item.id)"
                                      >
                                        Çalışmayı Arşivle
                                      </h5>
                                    </button></v-list-item
                                  >
                                  <router-link
                                    to="/worksdetails/?tab=calismaekibi"
                                  >
                                    <v-list-item
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Çalışma Ekibi
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                  <router-link
                                    to="/worksdetails/?tab=calismadosyalari"
                                  >
                                    <v-list-item
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Çalışma Dosyaları
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                  
                                    <v-list-item @click="goDetail(item.id)"
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Hastalar
                                      </h5></v-list-item
                                    >
                                 
                                  <router-link to="/worksdetails/?tab=hastalar">
                                    <v-list-item
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Hasta Ekle
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                </v-list>

                                <v-list
                                  v-for="(item2, index) in item.workMembers"
                                  :key="index"
                                >
                                  <v-list-item
                                    v-if="
                                      item2.authorizationGroup == 2 &&
                                        index == 0
                                    "
                                    ><button>
                                      <h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                        v-on:click="archiveWork(item.id)"
                                      >
                                        Çalışmayı Arşivle
                                      </h5>
                                    </button></v-list-item
                                  >
                                  <router-link
                                    to="/worksdetails/?tab=calismaekibi"
                                  >
                                    <v-list-item v-if="index == 0"
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Çalışma Ekibi
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                  <router-link
                                    to="/worksdetails/?tab=calismadosyalari"
                                  >
                                    <v-list-item v-if="index == 0"
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Çalışma Dosyaları
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                  
                                    <v-list-item v-if="index == 0" @click="goDetail(item.id)"
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Hastalar
                                      </h5></v-list-item
                                    >
                                 
                                  <router-link to="/worksdetails/?tab=hastalar">
                                    <v-list-item
                                      v-if="
                                        item2.authorizationGroup == 1 &&
                                          index == 0
                                      "
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Hasta Ekle
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                </v-list>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-card-title>

                        <v-card-text class="mt-5">
                          <v-row>
                            <v-col>
                              {{ item.workName }}
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col>
                              Tarih:
                              {{ getDateFormat(item.createDate) }} -
                              {{ getDateFormat(item.completionDate) }}
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col>
                              Protokol No: {{ item.protocolNumber }}
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col> SAK: {{ item.responsibleCro }} </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          <v-row>
                            <v-col style="margin-right:10%" class="col-2 ml-auto mb-2">
                              <router-link to="/worksdetails/?tab=hastalar">
                                <v-btn v-on:click="getWorkId(item.id)" icon
                                  ><v-icon>fas fa-arrow-right </v-icon>
                                </v-btn>
                              </router-link>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item value="arsivlenmiscalismalar">
                <v-container class="bv-example-row">
                  <v-row>
                    <v-col class="ml-auto" xl="4" lg="5" md="6">
                      <v-text-field
                        v-model="searchWork3"
                        append-icon="search"
                        label="Ara"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      xl="4"
                      lg="6"
                      md="6"
                      sm="12"
                      xs="12"
                      v-for="(item, index) in filteredArchivedList"
                      :key="index"
                    >
                      <v-card class="elevation-5">
                        <v-card-title>
                          <v-row>
                            <v-col class="col-10">
                              {{ item.workShortName }}
                            </v-col>
                            <v-col class="col-2 ml-auto">
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn text v-bind="attrs" v-on="on">
                                    ...
                                  </v-btn>
                                </template>

                                <v-list v-if="isShowGlobalAdmin">
                                  <router-link
                                    to="/worksdetails/?tab=calismaekibi"
                                  >
                                    <v-list-item
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Çalışma Ekibi
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                  <router-link
                                    to="/worksdetails/?tab=calismadosyalari"
                                  >
                                    <v-list-item
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Çalışma Dosyaları
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                  <router-link to="/worksdetails/?tab=hastalar">
                                    <v-list-item
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Hastalar
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                  <router-link to="/worksdetails/?tab=hastalar">
                                    <v-list-item
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Hasta Ekle
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                </v-list>

                                <v-list
                                  v-for="(item2, index) in item.workMembers"
                                  :key="index"
                                >
                                  <router-link
                                    to="/worksdetails/?tab=calismaekibi"
                                  >
                                    <v-list-item v-if="index == 0"
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Çalışma Ekibi
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                  <router-link
                                    to="/worksdetails/?tab=calismadosyalari"
                                  >
                                    <v-list-item v-if="index == 0"
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Çalışma Dosyaları
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                  <router-link to="/worksdetails/?tab=hastalar">
                                    <v-list-item v-if="index == 0"
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Hastalar
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                  <router-link to="/worksdetails/?tab=hastalar">
                                    <v-list-item
                                      v-if="
                                        item2.authorizationGroup == 1 &&
                                          index == 0
                                      "
                                      ><h5
                                        style="
                                              color: #4d345c;
                                              font-weight: bold;
                                            "
                                      >
                                        Hasta Ekle
                                      </h5></v-list-item
                                    >
                                  </router-link>
                                </v-list>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-card-title>

                        <v-card-text class="mt-5">
                          <v-row>
                            <v-col>
                              {{ item.workName }}
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col>
                              Tarih:
                              {{ getDateFormat(item.createDate) }} -
                              {{ getDateFormat(item.completionDate) }}
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col>
                              Protokol No: {{ item.protocolNumber }}
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col> SAK: {{ item.responsibleCro }} </v-col>
                          </v-row></v-card-text
                        >
                        <v-card-actions>
                          <!-- dialog islemleri -->
                          <v-row>
                            <v-col style="margin-right:10%" class="col-2 ml-auto mb-2">
                              <router-link to="/worksdetails/?tab=hastalar">
                                <v-btn v-on:click="getWorkId(item.id)" icon
                                  ><v-icon>fas fa-arrow-right </v-icon>
                                </v-btn>
                              </router-link>
                            </v-col>
                          </v-row>
                          <!-- dialog islemi bitti -->
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
  
</template>


<style lang="scss">
//@import "@/assets/sass/pages/wizard/wizard-3.scss";

@import "@/global.scss";
</style>

<script>
//import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_FROMWORKLIST } from "@/core/services/store/selectedpageitem.module";

//import KTUtil from "@/assets/js/components/util";
//import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
export default {
  name: "workslist",
  data() {
    return {
      searchWork: "",
      searchWork2: "",
      searchWork3: "",
      globaladmin: false,
      cro: false,
      user: false,
      isShow: false,
      id: "",
      lock: false,
      search: "",
      items2: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
      items: [
        { text: "Kilitli Çalışmalar", value: 0 },
        { text: "Arşive Alınmış Çalışmalar", value: 1 },
      ],
      userworksList: [],
      lockedWorksList: [],
      archivedWorksList: [],
      array: [],
      workMembers: [],
      msg:this.newmsg,
      loading:this.load
    };
  },
  props: {
    load: {
      type: Boolean,
      default: false,
    },
    newmsg: {
      type: String,
      default: "Lütfen Bekleyiniz...",
    },
    width: {
      type: Number,
      default: 300,
    },
    color: {
      type: String,
      default: "grey",
    },
    dark: {
      type: Boolean,
      default: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
    barColor: {
      type: String,
      default: "white",
    },
    indeterminate: {
      type: Boolean,
      default: true,
    },
    percentComplete: {
      type: Number,
      default: 100,
    },
  },

  mounted() {
    this.getUserWorks();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Çalışma Listesi", route: "/workslist" },
    ]);
    this.globaladmin = this.isGlobalAdmin;
    //   this.cro = this.isCro;
    //  this.user = this.isNormalUser;
    this.isShowGlobalAdmin = this.globaladmin;
    //  this.isShowCro= this.cro;
    //  this.isShowUser = this.user;
  },
  methods: {
    setFromWorkList(){
     this.$store.commit(SET_FROMWORKLIST,true);
  
    },
    goDetail(id){
      window.localStorage.setItem("getWorkID", id);

       this.$router.push({
        name: "worksdetails",
      });
    },
    getUserWorks() {
      this.loading=true;
      this.msg = "Çalışmalar Listeleniyor...";
      ApiService.get("userworks")
        .then(({ data }) => {
          console.log("Çalışmalar geldi", data.result);
          const resultData = data.result;
          this.userworksList = resultData.filter(
            (work) => !work.lock && !work.archive
          );
          this.lockedWorksList = resultData.filter(
            (work) => work.lock && !work.archive
          );
          this.archivedWorksList = resultData.filter((work) => work.archive);
          //console.log("userworkliste bak", this.userworksList);
          for (let index = 0; index < this.userworksList.length; index++) {
            this.workMembers = this.userworksList[index].workMembers;
          }
          this.loading=false;
          // this.workMembers =  this.userworksList.workMembers;
        })
        .catch((err) => {
          this.loading=false;
          if(err.status==401){
            this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
          }
          console.log("error", err);
        });
      //console.log("userworklist ", this.userworksList);
    },
   getDateFormat(item) {
      let date = item ? item.split("T")[0] : "-";
     // console.log("date",date);
      let date2 = new Date(date).toLocaleString("tr");
      //console.log("date",date2);
      let date3 =date2?date2.split(" ")[0]: "-";

      return date3;
    },
    getWorkId(item) {
      //console.log("calısmaya gite tıkladın", item);
      window.localStorage.setItem("getWorkID", item);
    },
    lockWork(itemId) {
      let body = { id: itemId, lock: true };
      ApiService.post("lockwork", body)
        .then(() => {
          this.getUserWorks();
          Swal.fire({
            icon: "success",
            title: "Çalışma Kilitlendi.",
            showConfirmButton: false,
            timer: 1200,
          });
        })
        .catch((err) => {
          Swal.fire({
            title: "Hata! Çalışma Kilitlenemedi.",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    archiveWork(itemId) {
      let body = { id: itemId, archive: true };
      ApiService.post("archivework", body)
        .then(() => {
          this.getUserWorks();
          Swal.fire({
            icon: "success",
            title: "Çalışma Arşivlendi.",
            showConfirmButton: false,
            timer: 1200,
          });
        })
        .catch((err) => {
          Swal.fire({
            title: "Hata! Çalışma Arşivlenemedi.",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
  },
  computed: {
    ...mapGetters(["isGlobalAdmin", "isCro", "isNormalUser"]),
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        console.log("query", this.$route.query.tab);
        return this.$route.query.tab;
      },
    },
    //...mapGetters(["currentUserPersonalInfo", "currentUser"]),
    filteredList() {
      return this.userworksList.filter((item) => {
        return item.workShortName
          .toLowerCase()
          .includes(this.searchWork.toLowerCase());
      });
    },
    filteredLockedList() {
      return this.lockedWorksList.filter((item) => {
        return item.workShortName
          .toLowerCase()
          .includes(this.searchWork2.toLowerCase());
      });
    },
    filteredArchivedList() {
      return this.archivedWorksList.filter((item) => {
        return item.workShortName
          .toLowerCase()
          .includes(this.searchWork3.toLowerCase());
      });
    },
  },
};
</script>
